'use client'

import { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { Grid } from '@mui/material'

// Types
import type { SlideshowProps } from '@/types/components/molecules/slideshow'
import type { ImageProps } from '@/types/components/atoms/shapes/image'

// Components
const ImageAtom = dynamic(() => import('@/components/atoms/shapes/image'))

const SlideshowMolecule = (props: SlideshowProps) => {
	// Props
	const { backgroundRef, images, showSelectedNumber } = props

	// Variables
	const [show, setShow] = useState<number>(0)
	const [selectedImages, setSelectedImages] = useState<ImageProps[]>([])

	// Callbacks
	const selectRandomImages = useCallback(() => {
		const shuffledImages = [...images].sort(() => 0.5 - Math.random())
		setSelectedImages(shuffledImages.slice(0, showSelectedNumber))
	}, [images, showSelectedNumber])

	useEffect(() => {
		selectRandomImages()
	}, [selectRandomImages])

	useEffect(() => {
		if (selectedImages.length && backgroundRef.current) backgroundRef.current.style.backgroundColor = selectedImages[show].color ?? 'lightblue'
	}, [backgroundRef, selectedImages, show])

	useEffect(() => {
		const interval = setInterval(() => {
			setShow((show + 1) % selectedImages.length)
		}, 10500)

		return () => clearInterval(interval)
	}, [selectedImages, show])

	return (
		<Grid width={1} height={1} position="relative" overflow="hidden">
			{selectedImages.map((image, index) => {
				return (
					<ImageAtom
						key={index}
						width={image.width}
						height={image.height}
						src={image.src}
						alt={image.alt}
						sx={{
							opacity: index === show ? 1 : 0,
							width: 1,
							height: 1,
							position: 'absolute',
							top: 0,
							left: 0,
							transition: `opacity ${index === show ? '1' : '.5'}s ease`,
							zIndex: index === show ? 2 : 1,
							'& img': {
								width: 1,
								height: 1,
								objectFit: 'cover',
								objectPosition: 'top center'
							}
						}}
					/>
				)
			})}
		</Grid>
	)
}

export default SlideshowMolecule
